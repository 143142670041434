import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';

import '../styles/Home.scss';

import ReactGA from 'react-ga';
// ReactGA.initialize('UA-103044249-2');
// ReactGA.pageview(window.location.pathname + window.location.search);

// import ReactGA from "react-ga4";
// ReactGA.initialize("G-38L4Y9TVZ3");
// ReactGA.send("pageview");

let timeout;
// let server = 'http://128.199.200.225:9500/';
// let server = "https://translation-server-proxy-erzx8.ondigitalocean.app/";
let server = "https://translation-server-proxy-7ja5f.ondigitalocean.app/" //Pikrex Team

export default class Home extends Component {
  constructor (){
    super();
    
    this.state = {
      output: ""
    };

    this.textInput = React.createRef();
    
    this.requestTranslation = this.requestTranslation.bind(this);
    this.handleText = this.handleText.bind(this);
    this.handleClick = this.handleClick.bind(this);
    //this.handleInput = this.handleInput.bind(this);
  }

  async requestTranslation(inputText) {
    const response = await fetch(server, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({content: inputText, message: "translate sentence"})
    });
    
    const translationOutput = await response.json();
    return translationOutput;
  }

  async handleText(textContent) {
    if (textContent.length >= 3) {
      const translationFromServer = await this.requestTranslation(textContent);

      this.setState({ output: translationFromServer });
      console.log("Translation from server:", this.state.output);
    }
  }

  handleClick(e) {
    let textContent = this.textInput.current.innerText;

    clearTimeout(timeout);
    timeout = setTimeout(() => { this.handleText(textContent); }, 150);
  }

  /*
  handleInput(e) {
    let textContent = e.currentTarget.textContent;

    clearTimeout(timeout);
    timeout = setTimeout(() => { this.handleText(textContent); }, 300);
  }
  */

  componentDidMount() {
    ReactGA.initialize("UA-103044249-2");
    ReactGA.pageview("/")
  }

  render() {
    return (
      <div className="view-wrapper">
        <div className="container">
          <div className="controls">
            <div className="languages">
              <div className="languages__item">Japanese</div>
              <div className="languages__icon">
                <FontAwesomeIcon icon={faArrowRightArrowLeft} />
              </div>
              <div className="languages__item">English</div>
            </div>
            <button className="button" onClick={this.handleClick}>Translate</button>
          </div>
        </div>
        <div className="container">
          <div className="source">
            <div spellCheck="false" contentEditable="true" className="source__input" ref={this.textInput}></div>
            <div spellCheck="false" className="source__input">
              {this.state.output}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
