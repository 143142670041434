import React, { Component } from 'react';
import '../styles/Auth.scss';

export default class Auth extends Component {
  state = { login : true }

  render(){
    return (
      <main className="view-wrapper">
        <form className={`auth-form form ${this.state.login ? '' : 'hidden'}`}>
          <div class="form-title">Login</div>
          <div class="form-description">
            Welcome back! Please enter your details.
          </div>
          <label class="form-group">
            <span class="form-group-text">Username</span>
            <input type="text" class="form-control" />
          </label>
          <label class="form-group">
            <span class="form-group-text">Password</span>
            <input type="password" class="form-control" />
          </label>
          <button class="form-submit">
            <span class="form-submit-icon">
              <i class="fa-solid fa-right-to-bracket"></i>
            </span>
            <span class="form-submit-text">Sign in</span>
          </button>
        </form>
        <form className={`auth-form form ${this.state.login ? 'hidden' : ''}`}>
          <div class="form-title">Register</div>
          <div class="form-description">
            Welcome! Please enter your details.
          </div>
          <label class="form-group">
            <span class="form-group-text">Username</span>
            <input type="text" class="form-control" />
          </label>
          <label class="form-group">
            <span class="form-group-text">Password</span>
            <input type="password" class="form-control" />
          </label>
          <label class="form-group">
            <span class="form-group-text">Password confirm</span>
            <input type="password" class="form-control" />
          </label>
          <button class="form-submit">
            <span class="form-submit-icon">
              <i class="fa-solid fa-right-to-bracket"></i>
            </span>
            <span class="form-submit-text">Sign up</span>
          </button>
        </form>
        <div class="form-switcher">
          <div className={`form-switcher-text ${this.state.login ? '' : 'hidden'}`}>
            Don't have an account? <span class="select-form" onClick={(e) => this.setState({ login: false })}>Sign up</span>
          </div>
          <div className={`form-switcher-text ${this.state.login ? 'hidden' : ''}`}>
            Already have an account? <span class="select-form" onClick={(e) => this.setState({ login: true })}>Sign in</span>
          </div>
        </div>
      </main>
    );
  }
}
