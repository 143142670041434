import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import '../styles/Contact.scss';

export default class Contact extends Component {
  render() {
    return (
      <div className="view-wrapper">
        <div className="container">
          <div className="title">
            Lets get in touch...
          </div>
          <div className="subtitle">
            Contact us via one of the options below! <FontAwesomeIcon icon={faArrowDown} />
          </div>
          <ul className="contact">
            <li>
              <a href="mailto:sugoitranslator@gmail.com" className="contact-item">
                <span className="contact-item-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <span className="contact-item-text">sugoitranslator@gmail.com</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}