import * as React from 'react';
import { Outlet, NavLink } from 'react-router-dom';

import './styles/App.scss';

export default function App() {
  return (
    <div className="enclosing">
      <nav className="navbar">
        <div className="container">
          <ul className="navbar-menu">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
              <a href="https://blog.sugoitranslator.com" target="_blank">Blog</a>
            </li>
            <li className="navbar-right">
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
    </div>
  );
}